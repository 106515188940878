import { IconButton } from '@mui/material';
import InstallMobileRounded from '@mui/icons-material/InstallMobileRounded';
import { Android, Apple } from '@mui/icons-material';

const NavGoToMobileApp = (props) => {
  return (
    <div style={{
      display:'flex',
      flexDirection:'column', 
      textAlign:'center',
    }}>
      <div style={{
        color: "#5A9BD8",
        paddingTop:2 
      }}>
        <div>
          <IconButton aria-label="Install Mobile"style={{height: '30px'}} href='https://play.google.com/store/apps/details?id=com.nach.doneright'>
            <Android height={15} style={{color:'#5A9BD8'}}/>
          </IconButton>
          {/* <IconButton aria-label="Install Mobile"style={{height: '30px'}} href='https://play.google.com/store/apps/details?id=com.nach.doneright'>
            <Apple height={15} style={{color:'#5A9BD8'}}/>
          </IconButton> */}
        </div>
      </div>
      <div style={{
  fontSize: 12,
  color: "#5A9BD8",
  textAlign: "center",  
  textDecoration: "none",
  display: "inline-block",
  paddingBottom: '0.5rem'
}}>
  <a className="navitemtext" href="#" style={{ color: "inherit" }}>
    Download <br />
    Mobile app
  </a>
</div>

    </div>
  );
};
export default NavGoToMobileApp;