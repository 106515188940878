import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  Typography,
  IconButton,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useCreateMultiResCalSlotMutation } from "../../Store/Services/ResCalSlotService";
import { useGetResCalSlotByResCalIdQuery } from "../../Store/Services/ResCalService";
import { useGetAllCalsQuery } from "../../Store/Services/CalService";
import { useGetAllSlotsQuery } from "../../Store/Services/SlotService";
import styles from "./Resource.module.css";
import { useGetResCalByIdQuery } from "../../Store/Services/ResourceService";
import "./ResourceCalander.css";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const schema = yup.object({});

const AvailabilityResource = ({
  handleClose,
  handleDateClick: parentHandleDateClick,
}) => {
  const resource = useSelector((state) => state.resource);
  const resourceId = resource.selectedResource;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [resCalId, setResCalId] = useState(null);
  const [resCalSlotDetails, setResCalSlotDetails] = useState(null);
  const [slotDetails, setSlotDetails] = useState(null);
  const [selectAllSlots, setSelectAllSlots] = useState(false);

  const {
    data: resCalSlotData,
    isSuccess: resCalSlotSuccess,
    isLoading: resCalSlotLoading,
  } = useGetResCalSlotByResCalIdQuery(resCalId ? resCalId : skipToken);

  useEffect(() => {
    if (resCalId && resCalSlotData) {
      setResCalSlotDetails(resCalSlotData);
    }
  }, [resCalId, resCalSlotData]);

  const {
    data: slotData,
    isSuccess: slotSuccess,
    isLoading: slotLoading,
  } = useGetAllSlotsQuery();

  useEffect(() => {
    if (!resCalId && slotData) {
      setResCalSlotDetails(slotData);
    }
  }, [resCalId, slotData]);

  // DATE AND SLOTS

  const { data: allCalsData, error: allCalsError } = useGetAllCalsQuery();
  const [selectedDates, setSelectedDates] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [allCalsDataAvailable, setAllCalsDataAvailable] = useState([]);
  const [selectedCalId, setSelectedCalId] = useState(null);
  const [deletedSlots, setDeletedSlots] = useState([]);

  const {
    data: resCalData,
    isLoading,
    isError,
  } = useGetResCalByIdQuery(resourceId);

  useEffect(() => {
    if (isError) {
      console.error("Error fetching resource calendar data:", isError);
    }
    if (resCalData) {
      console.log("all resCalData:", resCalData);
    }
  }, [resCalData, isLoading, isError]);

  useEffect(() => {
    if (allCalsError) {
      console.error("Error fetching availability data:", allCalsError);
    }
    if (allCalsData) {
      const unavailableDates = allCalsData
        .filter((cal) => cal.availability === 0)
        .map((cal) => cal.day);

      setUnavailableDates(unavailableDates);
      setAllCalsDataAvailable(allCalsData);
    }
  }, [allCalsData, allCalsError]);

  const isDateSelected = (formattedDate) => {
    return selectedDates.includes(formattedDate);
  };

  const handleDateClick = (formattedDate, calId) => {
    const calDataForDate = allCalsDataAvailable.find(
      (cal) => cal.day === formattedDate
    );
    setSelectedCalId(calId);

    if (!calDataForDate) {
      toast.error("Selected date is not available yet!", {
        position: "bottom-right",
      });
    } else if (calDataForDate.availability !== 1) {
      toast.error("Selected date is not available!", {
        position: "bottom-right",
      });
    } else {
      setSelectedDates(formattedDate);

      if (resCalData && Array.isArray(resCalData) && resCalData.length > 0) {
        const resCal = resCalData.find(
          (resCal) => resCal.calId === calDataForDate.calId
        );
        setResCalId(resCal?.resCalId);
      } else {
        console.error(
          "No resCalData available or it is not in the expected format."
        );
      }
    }
  };

  const [checkedSlotIds, setCheckedSlotIds] = useState([]);

  const handleSlotToggle = (slotId, isChecked) => {
    let resCalSlotId = null;

    if (resCalSlotDetails && resCalSlotDetails.slots) {
      const slot = resCalSlotDetails.slots.find(
        (slot) => slot.slotId === slotId
      );
      resCalSlotId = slot ? slot.resCalSlotId : null;
    }

    if (isChecked) {
      setCheckedSlotIds((prevCheckedSlotIds) => [
        ...prevCheckedSlotIds,
        slotId,
      ]);
    } else {
      setCheckedSlotIds((prevCheckedSlotIds) =>
        prevCheckedSlotIds.filter((id) => id !== slotId)
      );
    }

    if (resCalSlotDetails && resCalSlotDetails.slots) {
      const updatedSlots = resCalSlotDetails.slots.map((slot) => {
        if (slot.slotId === slotId) {
          return { ...slot, availability: isChecked ? 1 : 0 };
        }
        return slot;
      });
      setResCalSlotDetails({ ...resCalSlotDetails, slots: updatedSlots });
    } else if (slotData) {
      const updatedSlots = slotData.map((slot) => {
        if (slot.slotId === slotId) {
          return { ...slot, availability: isChecked ? 1 : 0 };
        }
        return slot;
      });
      setResCalSlotDetails({ ...resCalSlotDetails, slots: updatedSlots });
    }

    if (resCalSlotId) {
      setDeletedSlots((prevDeletedSlots) => [
        ...prevDeletedSlots,
        resCalSlotId,
      ]);
    }
  };

  const handleSelectAllToggle = (event) => {
    const { checked } = event.target;
    setSelectAllSlots(checked);

    const updatedSlots = resCalSlotDetails.slots.map((slot) => ({
      ...slot,
      availability: checked ? 1 : 0,
    }));
    setResCalSlotDetails({ ...resCalSlotDetails, slots: updatedSlots });
  };

  // ------- CALANDER COMPONENT --------- //

  const generateCalendar = (year, month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const allCalsDataAvailable = allCalsData || [];

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const unavailableDates = allCalsDataAvailable
      .filter((cal) => cal.availability === 0)
      .map((cal) => cal.day);

    const resCalDays = resCalData
      ? resCalData.map((resCalItem) => resCalItem.day)
      : [];

    const isResCalDay = (formattedDate) => resCalDays.includes(formattedDate);

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const isCurrentDate =
        currentYear === year && currentMonth === month && currentDay === i;
      const formattedDate = new Date(year, month, i).toLocaleDateString(
        "en-CA",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );

      const isUnavailableDate = unavailableDates.includes(formattedDate);
      const isClickedDate =
        formattedDate ===
        new Date(selectedDates[selectedDates.length - 1]).toLocaleDateString(
          "en-CA",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
        );

      const calDataForDate = allCalsDataAvailable.find(
        (cal) => cal.day === formattedDate
      );

      const isResCalDate = isResCalDay(formattedDate);

      const dayClassName = `res-calendar-day 
      ${isCurrentDate ? "current-date" : ""}
      ${isUnavailableDate ? "unavailable" : ""}
      ${isDateSelected(formattedDate) ? "selected" : ""}
      ${calDataForDate?.availability === 1 ? "available" : ""}
      ${isResCalDate ? "res-cal-date" : ""}`;

      days.push(
        <div
          key={i}
          className={dayClassName}
          onClick={() =>
            handleDateClick(
              formattedDate,
              calDataForDate?.calId,
              calDataForDate?.resCalId
            )
          }
        >
          {i}
        </div>
      );
    }

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.unshift(
        <div
          key={`placeholder-${i}`}
          className="res-calendar-day placeholder"
        />
      );
    }

    return days;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return { currentYear, currentMonth };
  };

  const [currentYear, setCurrentYear] = useState(getCurrentDate().currentYear);
  const [currentMonth, setCurrentMonth] = useState(
    getCurrentDate().currentMonth
  );

  const navigateMonth = (offset) => {
    setCurrentMonth((prevMonth) => (prevMonth + offset + 12) % 12);
  };

  const navigateYear = (offset) => {
    setCurrentYear((prevYear) => prevYear + offset);
  };

  const getMonthName = (monthIndex) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  };

  //SUBMIT
  const [createMultiResCalSlot] = useCreateMultiResCalSlotMutation();

  const onSubmit = async (data) => {
    const slots = [];

    checkedSlotIds.forEach((slotId) => {
      const slotObject = {
        slotId: slotId,
      };
      slots.push(slotObject);
    });

    const availabilityData = [
      {
        calId: selectedCalId,
        resCalId: resCalId,
        slots,
        availability: 1,
      },
    ];

    const requestBody = {
      resId: resourceId,
      availabilityData,
      deleted: { resCalSlotId: deletedSlots },
    };

    try {
      const response = await createMultiResCalSlot(requestBody);
      handleClose();
    } catch (error) {
      console.error("Error saving resCalSlot details:", error);
    }
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Resource Availability
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <div className="res-custom-calendar">
              <div className="res-calendar-navigation-container">
              <div className="calendar-navigation-container">
                <IconButton
                  className="res-navigation-icon"
                  onClick={() => navigateYear(-1)}
                >
                  &lt;&lt;
                </IconButton>
                <IconButton
                  className="res-navigation-icon"
                  onClick={() => navigateMonth(-1)}
                >
                  &lt;
                </IconButton>
              </div>
                <div className="res-calendar-header">
                  {`${getMonthName(currentMonth)} ${currentYear}`}
                </div>
                <div className="calendar-navigation-container">
                <IconButton
                  className="res-navigation-icon"
                  onClick={() => navigateMonth(1)}
                >
                  &gt;
                </IconButton>
                <IconButton
                  className="res-navigation-icon"
                  onClick={() => navigateYear(1)}
                >
                  &gt;&gt;
                </IconButton>
              </div>
              </div>
              <div className="res-days-row">
                <div className="res-day">Sun</div>
                <div className="res-day">Mon</div>
                <div className="res-day">Tue</div>
                <div className="res-day">Wed</div>
                <div className="res-day">Thu</div>
                <div className="res-day">Fri</div>
                <div className="res-day">Sat</div>
              </div>

              <div className="res-calendar-days">
                {generateCalendar(currentYear, currentMonth, handleDateClick)}
              </div>

            </div>{" "}
          </Grid>
          {/* GAP */}
          <Grid
            item
            xs={12}
            md={4}
            style={{ alignItems: "center", marginTop: "130px" }}
          >
            {/* {selectedDates.length > 0 && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllSlots}
                      onChange={handleSelectAllToggle}
                    />
                  }
                  label="Select All"
                />
              </div>
            )} */}
            {resCalSlotDetails && resCalSlotDetails.slots ? (
              resCalSlotDetails.slots.map((slot) => (
                <div key={slot.slotId}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={slot.availability === 1}
                        onChange={(event) =>
                          handleSlotToggle(slot.slotId, event.target.checked)
                        }
                      />
                    }
                    label={slot.slot}
                  />
                </div>
              ))
            ) : slotData ? (
              slotData.map((slot) => (
                <div key={slot.slotId}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={slot.availability === 1}
                        onChange={(event) =>
                          handleSlotToggle(slot.slotId, event.target.checked)
                        }
                      />
                    }
                    label={slot.slot}
                  />
                </div>
              ))
            ) : (
              <div>Loading slots...</div>
            )}
          </Grid>
        </Grid>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default AvailabilityResource;
