// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.GuestDetails_headingContainer__CNJZd {
    display: flex;
    justify-content: space-between;
}

.GuestDetails_closeButton__Vswuw {
    padding: 0.2rem;
    border: 1px #5A9BD8 solid;
    color: #5A9BD8;
}

.GuestDetails_formItem__91trJ {
    margin-top: 1rem;
}

.GuestDetails_formLabel__rfYmY {
    font-weight: 600;
}

.GuestDetails_formInput__aqDH2 {
    margin-top: 0.3rem !important;
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 2px 2px 50px 2px #657FAD1A;
}

.GuestDetails_validation__mWNTc {
    color: red;
}

.GuestDetails_buttonContainer__H-LC1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.GuestDetails_submitButton__r5gsr {
    border-radius: 1rem;
    width: 10rem;
}

.GuestDetails_locationButton__Ip24h {
    border-radius: 1rem;
    width: 10rem;
    margin-left: 0.5rem;
}

.GuestDetails_dialog__VhpqL{
    border-radius: 10px;
    background: linear-gradient(91deg, rgba(90, 155, 216, 0.1) 23.22%, rgba(90, 155, 216, 0) 99.28%);
}`, "",{"version":3,"sources":["webpack://./src/Components/Checkout/GuestDetails.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,wBAAwB;IACxB,gBAAgB;IAChB,sCAAsC;AAC1C;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,gGAAgG;AACpG","sourcesContent":["\r\n.headingContainer {\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.closeButton {\r\n    padding: 0.2rem;\r\n    border: 1px #5A9BD8 solid;\r\n    color: #5A9BD8;\r\n}\r\n\r\n.formItem {\r\n    margin-top: 1rem;\r\n}\r\n\r\n.formLabel {\r\n    font-weight: 600;\r\n}\r\n\r\n.formInput {\r\n    margin-top: 0.3rem !important;\r\n    border-radius: 0.9375rem;\r\n    background: #FFF;\r\n    box-shadow: 2px 2px 50px 2px #657FAD1A;\r\n}\r\n\r\n.validation {\r\n    color: red;\r\n}\r\n\r\n.buttonContainer {\r\n    margin-top: 2rem;\r\n    margin-bottom: 1rem;\r\n    display: flex;\r\n    justify-content: center;\r\n    gap: 1rem;\r\n}\r\n\r\n.submitButton {\r\n    border-radius: 1rem;\r\n    width: 10rem;\r\n}\r\n\r\n.locationButton {\r\n    border-radius: 1rem;\r\n    width: 10rem;\r\n    margin-left: 0.5rem;\r\n}\r\n\r\n.dialog{\r\n    border-radius: 10px;\r\n    background: linear-gradient(91deg, rgba(90, 155, 216, 0.1) 23.22%, rgba(90, 155, 216, 0) 99.28%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingContainer": `GuestDetails_headingContainer__CNJZd`,
	"closeButton": `GuestDetails_closeButton__Vswuw`,
	"formItem": `GuestDetails_formItem__91trJ`,
	"formLabel": `GuestDetails_formLabel__rfYmY`,
	"formInput": `GuestDetails_formInput__aqDH2`,
	"validation": `GuestDetails_validation__mWNTc`,
	"buttonContainer": `GuestDetails_buttonContainer__H-LC1`,
	"submitButton": `GuestDetails_submitButton__r5gsr`,
	"locationButton": `GuestDetails_locationButton__Ip24h`,
	"dialog": `GuestDetails_dialog__VhpqL`
};
export default ___CSS_LOADER_EXPORT___;
