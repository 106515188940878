import { Button, Container, Dialog, DialogContent, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom"; 
import React from 'react';

const GuestDialog = ({ open, onClose }) => {
  const navigate = useNavigate(); 
  const onContinueClick = () => {
    navigate("/profile/overview"); 
    onClose();
  };

  return (
    <Dialog
      fullWidth={true}
      sx={{
        marginLeft: '25rem',
        marginRight: '25rem'
      }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Container maxWidth="md">
          <Typography
            my={3}
            variant="h5"
            component="div"
            color="text.primary"
            textAlign="center"
            fontWeight="bold"
          >
            You have successfully logged in!
          </Typography>

          <Typography
            my={3}
            component="div"
            color="text.primary"
            textAlign="center"
            fontWeight="400"
          >
            Please update your details for a better experience with MissMasters.
          </Typography>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: "1rem"
            }}
          >
            <Button
              onClick={onContinueClick}
              style={{ margin: '0.5rem', width: "20rem" }}
              fullWidth
              variant="contained"
              aria-label="Go to Profile"
            >
              Go to Profile
            </Button>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default GuestDialog;
