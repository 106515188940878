import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Typography,
  Button,
  Grid,
  OutlinedInput,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import CategoryList from "../Shared/CategoryList";
import styles from "./EditRequest.module.css";
import { useGetAllNoHrsQuery } from "../../Store/Services/NoOfHrsService";
import { useGetAllNoResQuery } from "../../Store/Services/NoOfResourceService";
import { useGetAllStatesQuery } from "../../Store/Services/StateService";
import { useGetAllAreasQuery } from "../../Store/Services/AreaService";
import { useGetAllCatWithSubCatQuery } from "../../Store/Services/CategoryService";
import { useUpdateRequestMutation } from "../../Store/Services/RequestService";
import { useGetnextTenDaysQuery } from "../../Store/Services/DatesService";
import { useGetAllSlotsQuery } from "../../Store/Services/SlotService";
import { useGetAllFrequenciesQuery } from "../../Store/Services/FrequencyService";
import { useLazyGetResourceByAreaDateTimeQuery } from "../../Store/Services/ResourceService";
import { useSelector } from "react-redux";

const schema = yup.object({
  catId: yup.string().required(""),
  noRes: yup.string().required(""),
  noHrs: yup.string().required(""),
  stateId: yup.string().required(""),
  areaId: yup.string().required(""),
  freqId: yup.string().required(""),
  timeSlotId: yup.string().required(""),
  dateId: yup.string().required(""),
  addressLine1: yup.string().required(""),
  addressLine2: yup.string().required(""),
  addressLine3: yup.string().required(""),
  email: yup.string().required(""),
});

const EditFields = ({
  requestData,
  setCatName,
  setSelectedCat,
  selectedCat,
  catName,
  reqId
}) => {
  // console.log("Edit Request Id" , reqId)
 

  const [noResOptions, setNoResOptions] = useState([]);
  const [noHrsOptions, setNoHrsOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [slotOptions, setSlotOptions] = useState([]);
  const [freqOptions, setFreqOptions] = useState([]);
  const [resOptions , setResOptions] = useState([]);
  // const [selectedCat, setSelectedCat] = useState("");
  const [areaOptions, setAreaOptions] = useState([]);
  const [openCatList, setOpenCatList] = useState(false);



  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: noResData } = useGetAllNoResQuery();
  const { data: noHrsData } = useGetAllNoHrsQuery();
  const { data: statesData } = useGetAllStatesQuery();
  const { data: areasData } = useGetAllAreasQuery();
  const { data: categories, isSuccess: categoriesSuccess } =
    useGetAllCatWithSubCatQuery();
  const { data: dates, isSuccess: dateSuccess } = useGetnextTenDaysQuery();
  const { data: slotsData, isSuccess: slotSuccess } = useGetAllSlotsQuery();
  const { data: freqData } = useGetAllFrequenciesQuery();
  const {data:resData} = useLazyGetResourceByAreaDateTimeQuery();

  useEffect(() => {
    if (noResData) {
      const mappedOptions = noResData.map((option) => ({
        value: option.noRes,
        label: option.noRes,
      }));
      setNoResOptions(mappedOptions);
    }

    if (resData) {
      const mappedOptions = resData.map((option) => ({
        value: option.resourceId,
        label: option.resource,
      }));
      setResOptions(mappedOptions);
    }


    if (freqData) {
      const mappedOptions = freqData.map((option) => ({
        value: option.freqId,
        label: option.freqName,
      }));
      setFreqOptions(mappedOptions);
    }

    if (dates) {
      const mappedOptions = dates.map((option) => ({
        value: option.dateId,
        label: option.date,
      }));
      setDateOptions(mappedOptions);
    }

    if (slotsData) {
      const mappedOptions = slotsData.map((option) => ({
        value: option.slotId,
        label: option.slot,
      }));
      setSlotOptions(mappedOptions);
    }

    if (noHrsData) {
      const mappedOptions = noHrsData.map((option) => ({
        value: option.noHrs,
        label: option.noHrs,
      }));
      setNoHrsOptions(mappedOptions);
    }

    if (statesData) {
      const mappedOptions = statesData.map((state) => ({
        value: state.stateId,
        label: state.state,
      }));
      setStateOptions(mappedOptions);
    }

    if (areasData) {
      const mappedOptions = areasData.map((area) => ({
        value: area.areaId,
        label: area.desc,
      }));
      setAreaOptions(mappedOptions);
    }
  }, [noResData, noHrsData, statesData, areasData, dates, slotsData, freqData , resData]);


  const [updateRequest] = useUpdateRequestMutation(reqId);

  const onSubmit = async (data) => {
    try {
      const formData = {
        reqId:reqId,
        catId: selectedCat,
        resourceId:data.resourceId,
        dateId: parseInt(data.dateId),
        noRes: parseInt(data.noRes),
        noHrs: parseInt(data.noHrs),
        stateId: parseInt(data.stateId),
        areaId: parseInt(data.areaId),
        freqId: parseInt(data.freqId),
        timeSlotId: parseInt(data.timeSlotId),
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        addressLine3: data.addressLine3,
        email: data.email,
      };
      await updateRequest(formData).unwrap();
      toast.success("Request successfully updated!", {
        position: "bottom-right",
      });
    } catch (err) {
      console.error("Failed to save the request", err);
      toast.error("Error, something went wrong!", {
        position: "bottom-right",
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={styles.formItem}> 
                  <label className={styles.formLabel}>Category :</label>
                  <OutlinedInput
                    {...register("catId")}
                    placeholder="Category Name"
                    fullWidth
                    className={styles.formInput}
                    onClick={() => setOpenCatList(true)}
                    error={!!errors.catName}
                    helperText={errors.catName?.message}
                    value={catName || ""}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>No Of Resource:</label>
                  <Controller
                    name="noRes"
                    control={control}
                    defaultValue={requestData?.noRes || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {noResOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
               <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>No Of Hours:</label>
                  <Controller
                    name="noHrs"
                    control={control}
                    defaultValue={requestData?.noHrs || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {noHrsOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>State:</label>
                  <Controller
                    name="stateId"
                    control={control}
                    defaultValue={requestData?.stateId || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {stateOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Area:</label>
                  <Controller
                    name="areaId"
                    control={control}
                    defaultValue={requestData?.areaId || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {areaOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Slot:</label>
                  <Controller
                    name="timeSlotId"
                    control={control}
                    defaultValue={requestData?.timeSlotId || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {slotOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Date:</label>
                  <Controller
                    name="dateId"
                    control={control}
                    defaultValue={requestData?.dateId || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {dateOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Frequency :</label>
                  <Controller
                    name="freqId"
                    control={control}
                    defaultValue={requestData?.freqId || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {freqOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}> Resource :</label>
                  <Controller
                    name="resourceId"
                    control={control}
                    defaultValue={requestData?.resourceId || ""}
                    render={({ field }) => (
                      <Select
                        className={styles.formInput}
                        size="small"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        fullWidth
                      >
                        {resOptions.map((option) => (
                          <MenuItem key={option.value} value={0}> Auto Assign
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Address Line 1:</label>
                  <OutlinedInput
                    {...register("addressLine1")}
                    placeholder="Address Line 1"
                    fullWidth
                    className={styles.formInput}
                    error={!!errors.addressLine1}
                    helperText={errors.addressLine1?.message}
                    defaultValue={requestData?.addressLine1}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Address Line 2:</label>
                  <OutlinedInput
                    {...register("addressLine2")}
                    placeholder="Address Line 2"
                    fullWidth
                    className={styles.formInput}
                    error={!!errors.addressLine2}
                    helperText={errors.addressLine2?.message}
                    defaultValue={requestData?.addressLine2}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Address Line 3:</label>
                  <OutlinedInput
                    {...register("addressLine3")}
                    placeholder="Address Line 3"
                    fullWidth
                    className={styles.formInput}
                    error={!!errors.addressLine3}
                    helperText={errors.addressLine3?.message}
                    defaultValue={requestData?.addressLine3}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.formItem}>
                  <label className={styles.formLabel}>Email:</label>
                  <OutlinedInput
                    {...register("email")}
                    placeholder="Email"
                    fullWidth
                    className={styles.formInput}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    defaultValue={requestData?.email}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <CategoryList
        open={openCatList}
        onClose={() => setOpenCatList(false)}
        selectedCat={selectedCat}
        setCatName={setCatName}
        setSelectedCat={setSelectedCat}
        onlySubCategories={true}
      />
    </div>
  );
};

export default EditFields;
