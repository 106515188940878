import {
  Button,
  IconButton,
  OutlinedInput,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  useUpdateReviewMutation,
  useGetReviewByIdQuery,
} from "../../Store/Services/reviewService";
import { useSelector } from "react-redux";
import styles from "./Comment.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Rating } from "@mui/material";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  rating: yup
    .number()
    .min(1, "Select at least 1 star")
    .required("Rating is required"),
  review: yup.string().required("Review is required"),
});

const EditReview = ({ handleClose }) => {
  const review = useSelector((state) => state.review);

  const { data: reviewDetails, isSuccess: reviewSuccess } =
    useGetReviewByIdQuery(
      review.selectedReview ? review.selectedReview : skipToken
    );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [updateReview] = useUpdateReviewMutation();
  useEffect(() => {
    if (reviewSuccess && reviewDetails) {
      reset({
        name: reviewDetails.name,
        rating: reviewDetails.rating || 0,
        review: reviewDetails.review,
        displayInHomePage: reviewDetails.displayInHomePage,
      });
    }
  }, [reviewDetails, reviewSuccess, reset]);

  const submit = async (data) => {
    const formData = {
      ...data,
      reviewId: review.selectedReview,
      status: 1,
    };

    try {
      await updateReview(formData).unwrap();
      toast.success("Review successfully updated", {
        position: "bottom-right",
      });
      handleClose();
    } catch (err) {
      console.error("Failed to update review", err);
      toast.error("Error: Something went wrong", { position: "bottom-right" });
      handleClose();
    }
  };

  const handleRatingChange = (event, newValue) => {
    setValue("rating", newValue);
  };
  // console.log("Rating  watch:", watch("rating"));
  // console.log("Rating  reviewDetails:", reviewDetails?.rating);
  return (
    <div className={styles.addCouponContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.dialogHeader}>
          <Typography
            fontWeight={600}
            className={styles.dialogTitle}
            variant="h6"
          >
            Edit Review
          </Typography>
        </div>
        <div className={styles.close}>
          <IconButton className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        <div className={styles.formItem}>
          <label className={styles.formLabel}>Name:</label>
          <OutlinedInput
            {...register("name")}
            className={styles.formInput}
            placeholder="Enter Name"
            size="small"
            fullWidth
            error={!!errors.name}
          />
          {errors.name && (
            <span className={styles.validation}>Name is required</span>
          )}
        </div>

        <div style={{ marginTop: "1rem", display: "inline-flex" }}>
          <OutlinedInput hidden style={{ display: "none" }} />
          <label className={styles.formLabel}>
            Ratings:
          </label>
          <FormControlLabel
            control={
              <>
                <input type="number" hidden readOnly />
                <div style={{ marginLeft: "1rem" }}>
                <Rating
                  size="large"
                  precision={1}
                  value={
                    watch("rating") !== undefined
                      ? Number(watch("rating"))
                      : Number(reviewDetails?.rating)
                  }
                  onChange={handleRatingChange}
                />
                </div>
              </>
            }
          />
        </div>

        <div className={styles.formItem}>
          <label className={styles.formLabel}>Review:</label>
          <OutlinedInput
            {...register("review")}
            className={styles.formInput}
            placeholder="Enter Review"
            multiline
            minRows={3}
            fullWidth
            error={!!errors.review}
          />
          {errors.review && (
            <span className={styles.validation}>Review is required</span>
          )}
        </div>

        <div className={styles.formItem}>
          <FormControlLabel
            control={
              <Checkbox
                {...register("displayInHomePage")}
                checked={watch("displayInHomePage") || false}
                onChange={(e) =>
                  setValue("displayInHomePage", e.target.checked)
                }
              />
            }
            label="Display in Home Page"
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditReview;
