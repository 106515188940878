import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { Close, Edit, Visibility } from "@mui/icons-material";
import styles from "./Request.module.css";
import { useGetRequestByIdQuery } from "../../Store/Services/RequestService";
import EditFields from "./EditRequestDetails";
import { useGetAllCatWithSubCatQuery } from "../../Store/Services/CategoryService";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const RequestDetailsDialog = ({ onClose, reqId }) => {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(null);
  const [catName, setCatName] = useState("");
  const [selectedCat, setSelectedCat] = useState("");

  const {
    data: requestData,
    isLoading,
    isSuccess: requestSuccess,
    isError,
  } = useGetRequestByIdQuery(reqId);

  const { data: categories, isSuccess: categoriesSuccess } =
    useGetAllCatWithSubCatQuery();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(),
  });

  const toggleMode = () => {
    setEditMode((prevEditMode) => !prevEditMode);
  };

  useEffect(() => {
    if (
      requestSuccess &&
      Array.isArray(requestData) &&
      requestData.length > 0
    ) {
      const {
        catName,
        catId,
        resourceId,
        date,
        noRes,
        noHrs,
        freqName,
        freqId,
        addressLine1,
        addressLine2,
        addressLine3,
        email,
        remarks,
        stateId,
        areaId,
        timeSlot,
        dateId,
        timeSlotId,
      } = requestData[0];
      setFormData({
        catName,
        catId,
        resourceId,
        dateId,
        date,
        noRes,
        noHrs,
        freqName,
        freqId,
        addressLine1,
        addressLine2,
        addressLine3,
        email,
        remarks,
        stateId,
        areaId,
        timeSlot,
        timeSlotId,
      });
      setSelectedCat(catId);
      setCatName(catName);
      // setValue("catName", catName || "");
    }
  }, [requestData, requestSuccess]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogContent className={styles.dialogContent}>
        {isLoading ? (
          <Typography variant="body1" color="textSecondary"></Typography>
        ) : isError ? (
          <Typography variant="body1" color="error"></Typography>
        ) : (
          requestData &&
          requestData.length > 0 && (
            <div>
              {/* Header */}
              <div className={styles.headingContainer}>
                <Typography className={styles.modalTitle} variant="h5">
                  {requestData[0].catName}{" "}
                  {requestData[0].freqName && (
                    <label>({requestData[0].freqName})</label>
                  )}
                </Typography>
                <div>
                  {/* <IconButton
                className={editMode ? styles.btnsv : styles.btnse}
                onClick={toggleMode}
              >
                {editMode ? <Visibility /> : <Edit />}
              </IconButton> */}
                  <IconButton className={styles.closeButton} onClick={onClose}>
                    <Close />
                  </IconButton>
                </div>
              </div>
              <Typography
                variant="body1"
                fontWeight={600}
                className={styles.requesterLabel}
              >
                Requester :
                {`${requestData[0]?.requester?.firstName || ""} ${
                  requestData[0]?.requester?.lastName || ""
                }`}
              </Typography>

              {/* Service Details */}
              <div className={styles.sectionBox}>
                <Typography variant="h6" className={styles.sectionTitle}>
                  Request Details
                </Typography>
                <div className={styles.detailsContainer}>

                  {requestData[0].day && (
                    <>
                      <Typography className={styles.label}>Date:</Typography>
                      <Typography className={styles.value}>
                        {requestData[0].day}
                      </Typography>
                    </>
                  )}
                  <Typography className={styles.label}>Time:</Typography>
                  <Typography className={styles.value}>
                    {requestData[0].timeSlot}
                  </Typography>
                  <Typography className={styles.label}>Duration:</Typography>
                  <Typography className={styles.value}>
                    {requestData[0].noHrs} hrs
                  </Typography>

                  <Typography className={styles.label}>
                    No of Professionals:
                  </Typography>
                  <Typography className={styles.value}>
                    {requestData[0].noRes}
                  </Typography>

                  <Typography className={styles.label}>Materials:</Typography>
                  <Typography className={styles.value}>
                    {requestData[0].isMaterialsNeeded ? "Yes" : "No"}
                  </Typography>
                </div>
              </div>

              {/* Payment Details */}
              <div className={styles.sectionBox}>
                <Typography variant="h6" className={styles.sectionTitle}>
                  Payment Details
                </Typography>
                <div className={styles.detailsContainer}>
                  <Typography className={styles.label}>Price:</Typography>
                  <Typography className={styles.value}>
                    {process.env.REACT_APP_CURRENCY} {requestData[0].amount}
                  </Typography>

                  <Typography className={styles.label}>
                    Payment Method:
                  </Typography>
                  <Typography className={styles.value}>
                    {requestData[0].paymentMethod}
                  </Typography>
                </div>
              </div>

              <div className={styles.sectionBox}>
                <Typography variant="h6" className={styles.sectionTitle}>
                  Contact Details
                </Typography>
                <div className={styles.detailsContainer}>
                  {requestData[0].phone && (
                    <>
                      <Typography className={styles.label}>Phone:</Typography>
                      <Typography className={styles.value}>
                        {requestData[0].phone}
                      </Typography>
                    </>
                  )}

                  <Typography className={styles.label}>Mobile:</Typography>
                  <Typography className={styles.value}>
                    {requestData[0].mobile}
                  </Typography>

                  <Typography className={styles.label}>Email:</Typography>
                  <Typography className={styles.value}>
                    {requestData[0].email}
                  </Typography>
                  <Typography className={styles.label}>Address:</Typography>
                  <Typography className={styles.value}>
                    {`${requestData[0].addressLine1}, ${requestData[0].addressLine2}, ${requestData[0].addressLine3}`}
                  </Typography>
                </div>
              </div>

{/* //  hide this section if the resourceId is 0, i.e verified professional */}
{requestData[0].resourceId !== 0 && (
  <div className={styles.sectionBoxReq}>
  <Typography variant="h6" className={styles.sectionTitle}>
    Resource Details
  </Typography>
  <div className={styles.contentContainerReq}>
    <div className={styles.imageContainer}>
      <img
        src={
          requestData[0]?.resource?.pic
            ? `https://n-ach-file-directory.sgp1.digitaloceanspaces.com/${requestData[0].resource.pic}`
            : require("../../Assets/avatar.png")
        }
        alt="Resource Avatar"
        className={styles.avatarImage}
      />
    </div>

    <div className={styles.detailsContainerReq}>
      {requestData[0]?.resource?.name && (
        <div className={styles.detailRow}>
          <Typography className={styles.label}>Name:</Typography>
          <Typography className={styles.value}>
            {requestData[0].resource.name}
          </Typography>
        </div>
      )}
      {requestData[0]?.resource?.mobileNo && (
        <div className={styles.detailRow}>
          <Typography className={styles.label}>Mobile:</Typography>
          <Typography className={styles.value}>
            {requestData[0].resource.mobileNo}
          </Typography>
        </div>
      )}
      {requestData[0]?.resource?.email && (
        <div className={styles.detailRow}>
          <Typography className={styles.label}>Email:</Typography>
          <Typography className={styles.value}>
            {requestData[0].resource.email}
          </Typography>
        </div>
      )}
    </div>
  </div>
</div>
)}
              {/* Remarks */}
              <div className={styles.sectionBox}>
                <Typography variant="h6" className={styles.sectionTitle}>
                  Remarks
                </Typography>
                <div className={styles.detailsContainer}>
                  {requestData[0].resComment || requestData[0].validComment ? (
                    <>
                      {requestData[0].resComment && (
                        <>
                          <Typography className={styles.label}>
                            Resource Comment:
                          </Typography>
                          <Typography className={styles.value}>
                            {requestData[0].resComment}
                          </Typography>
                        </>
                      )}
                      {requestData[0].validComment && (
                        <>
                          <Typography className={styles.label}>
                            Validation Comment:
                          </Typography>
                          <Typography className={styles.value}>
                            {requestData[0].validComment}
                          </Typography>
                        </>
                      )}
                    </>
                  ) : (
                    <Typography>No remarks available</Typography>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RequestDetailsDialog;
