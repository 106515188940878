import { apiSlice } from "../Slices/apiSlice";

export const ReviewService = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllReviews: builder.query({
            query: () => "/reviews",
            providesTags: ["review"],
        }),

        getReviewById: builder.query({
            query: (reviewId) => `reviews/${reviewId}`,
            providesTags: ["review"],
        }),

        createReview: builder.mutation({
            query: (Details) => ({
                url: `/reviews`,
                method: 'POST',
                body: Details
            }),
            invalidatesTags: ["review"],
        }),

        updateReview: builder.mutation({
            query: (Details) => ({
                url: `/reviews/${Details.reviewId}`,
                method: 'PUT',
                body: Details
            }),
            invalidatesTags: ["review"],
        }),

        deleteReview: builder.mutation({
            query: (id) => ({
                url: `reviews/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["review"],
        }),

    })
});

export const {
    useGetAllReviewsQuery,
    useGetReviewByIdQuery,
    useCreateReviewMutation,
    useUpdateReviewMutation,
    useDeleteReviewMutation,
} = ReviewService;