import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Paper,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleLoginDialog,
  toggleRegisterDialog,
  selectAuth,
} from "../../Store/Slices/authSlice";
import LoginForm from "./LoginForm";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./LoginForm.module.css";
import { Link, useNavigate } from "react-router-dom";

const LoginDialog = ({ onCloseFinal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoginDialogOpen } = useSelector(selectAuth);

  const handleClose = () => {
    dispatch(toggleLoginDialog());
  };

  const handleSwitchToRegister = () => {
    dispatch(toggleLoginDialog());
    dispatch(toggleRegisterDialog());
  };

  const handleForgotPasswordClick = () => {
    handleClose();
    navigate("/forgot-password");
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isLoginDialogOpen}
      onClose={handleClose}
    >
      <Paper elevation={3} className={styles.paperStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              onClick={handleClose}
              style={{ position: "absolute", right: "8px", top: "8px" }}
              aria-label="Close"
            >
              <CloseIcon />
            </Button>
            <div className={styles.formContainer}>
              <img
                className={styles.logo}
                alt="logo"
                src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/doneRightLogo.png`}
                loading="lazy"
              />
              <Typography variant="h6" component="h3" className={styles.login}>
                Sign In
              </Typography>
              <LoginForm onClose={handleClose} onCloseFinal={onCloseFinal} />
              <Button
                className={styles.formItem}
                color="secondary"
                fullWidth
                onClick={handleSwitchToRegister}
              >
                Create an account
              </Button>
              <Divider sx={{ mt: -3, mb: -2 }}>OR</Divider>
              <Link to="/forgot-password" onClick={handleForgotPasswordClick}>
                <Button className={styles.formItem} color="secondary" fullWidth>
                  Forgot Password?
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Paper>{" "}
    </Dialog>
  );
};

export default LoginDialog;
