import React from "react";
import { useDispatch } from "react-redux"; 
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  IconButton,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toggleLoginDialog } from "../../Store/Slices/authSlice";

const ExistingUserDialog = ({ open, onClose, onLogin }) => {

    const dispatch = useDispatch(); 

    const handleLogin = () => {
        dispatch(toggleLoginDialog());
        onLogin(); 
      };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="existing-user-dialog-title"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0.5rem 1rem",
        }}
      >
        <IconButton
          style={{
            padding: "0.2rem",
            marginTop: "0.5rem",
            height: "2rem",
            border: "1px #5A9BD8 solid",
            color: "#5A9BD8",
          }}
          onClick={onClose}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography
          my={3}
          variant="h5"
          component="div"
          color="text.primary"
          textAlign="center"
          fontWeight="bold"
          marginBottom="2rem"
        >
          Looks like you already have an account
        </Typography>

        <Typography
          component="div"
          color="text.primary"
          textAlign="center"
          marginBottom="2rem"
        >
          Would you like to login?
        </Typography>

        <Button
        onClick={handleLogin}
        style={{
            borderRadius: "50px",
            marginBottom: "0.5rem",
            width: "200px",
            margin: "0 auto",
            display: "block",
          }}
          variant="contained"
          aria-label="Sign In"
        >
          Login
        </Button>

      </DialogContent>
    </Dialog>
  );
};

export default ExistingUserDialog;
