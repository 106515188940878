// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comment_dialogContent__tRLCx {
  background-color: #f8f9fa;
  padding: 20px;
  max-width: 800px;
}

.Comment_subheadingContainer__on9DJ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: #47c2bc98; 
  color: #000000;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  margin-top: 10px;

}

.Comment_headingContainer__CGmW5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.Comment_modalTitle__0yIvr {
  font-weight: 600;
}

.Comment_title__bXFLS {
  font-weight: 600;
}

.Comment_titleLabel__V02GV {
  margin-top: -20px;
}
.Comment_closeButton__Fuaet {
  padding: 5px;
  color: #47c2bc;
  margin-left: 300px;
}

.Comment_formContainer__Heimz {
  padding-left: 30px;
  padding-right: 30px;
}

.Comment_listItem__XTQIn {
  margin-bottom: 15px;
}

.Comment_commentTitle__ILjdl {
  font-weight: 600;
}

.Comment_errorText__1Ijrh {
  color: #dc3545;
}

.Comment_submitButton__LA8W9 {
  margin-top: 20px;
  width: 10rem;
}

.Comment_formInput__NvaoG {
  margin-top: 0.3rem;
}

.Comment_buttonContainer__7VkmJ{
  text-align: center;

}

.Comment_formLabel__--ovh {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Components/Comments/Comment.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;;AAElB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;;AAEpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dialogContent {\r\n  background-color: #f8f9fa;\r\n  padding: 20px;\r\n  max-width: 800px;\r\n}\r\n\r\n.subheadingContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 10px;\r\n  background-color: #47c2bc98; \r\n  color: #000000;\r\n  padding-top: 6px;\r\n  padding-bottom: 6px;\r\n  padding-left: 8px;\r\n  margin-top: 10px;\r\n\r\n}\r\n\r\n.headingContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.modalTitle {\r\n  font-weight: 600;\r\n}\r\n\r\n.title {\r\n  font-weight: 600;\r\n}\r\n\r\n.titleLabel {\r\n  margin-top: -20px;\r\n}\r\n.closeButton {\r\n  padding: 5px;\r\n  color: #47c2bc;\r\n  margin-left: 300px;\r\n}\r\n\r\n.formContainer {\r\n  padding-left: 30px;\r\n  padding-right: 30px;\r\n}\r\n\r\n.listItem {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.commentTitle {\r\n  font-weight: 600;\r\n}\r\n\r\n.errorText {\r\n  color: #dc3545;\r\n}\r\n\r\n.submitButton {\r\n  margin-top: 20px;\r\n  width: 10rem;\r\n}\r\n\r\n.formInput {\r\n  margin-top: 0.3rem;\r\n}\r\n\r\n.buttonContainer{\r\n  text-align: center;\r\n\r\n}\r\n\r\n.formLabel {\r\n  font-weight: 600;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": `Comment_dialogContent__tRLCx`,
	"subheadingContainer": `Comment_subheadingContainer__on9DJ`,
	"headingContainer": `Comment_headingContainer__CGmW5`,
	"modalTitle": `Comment_modalTitle__0yIvr`,
	"title": `Comment_title__bXFLS`,
	"titleLabel": `Comment_titleLabel__V02GV`,
	"closeButton": `Comment_closeButton__Fuaet`,
	"formContainer": `Comment_formContainer__Heimz`,
	"listItem": `Comment_listItem__XTQIn`,
	"commentTitle": `Comment_commentTitle__ILjdl`,
	"errorText": `Comment_errorText__1Ijrh`,
	"submitButton": `Comment_submitButton__LA8W9`,
	"formInput": `Comment_formInput__NvaoG`,
	"buttonContainer": `Comment_buttonContainer__7VkmJ`,
	"formLabel": `Comment_formLabel__--ovh`
};
export default ___CSS_LOADER_EXPORT___;
