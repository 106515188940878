import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  OutlinedInput,
  Grid,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from "./Comment.module.css";
import { useUpdateRequestStatusMutation } from "../../Store/Services/RequestService";
import { toast } from "react-toastify";

const CommentApproveDialog = ({ onClose, reqId }) => {
  const [comment, setComment] = useState("");
  const [UpdateRequest] = useUpdateRequestStatusMutation();

  console.log("Request ID:", reqId);  // Ensure reqId is correctly logged.

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!reqId) {
      console.error("Error: reqId is undefined");
      toast.error("Invalid request ID!", { position: "bottom-right" });
      return;
    }

    UpdateRequest({
      reqId: reqId,
      resStatusId: 2,
      sender: "Admin",
      remark: comment,
    })
      .unwrap()
      .then(() => {
        toast.success("Status successfully updated!", {
          position: "bottom-right",
        });
        onClose();
      })
      .catch((error) => {
        toast.error("Error updating request status!", {
          position: "bottom-right",
        });
        console.error("Error updating request status:", error);
      });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        <div className={styles.headingContainer}>
          <Typography fontWeight={600} variant={"h6"}>
            Approve Request
          </Typography>
          <IconButton className={styles.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}>Comment:</label>
              <OutlinedInput
                multiline
                className={styles.formInput}
                placeholder="Add your comment"
                fullWidth
                value={comment}
                onChange={handleCommentChange}
              />
            </div>
          </Grid>

          <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
            <Button
              type="submit"
              className={styles.submitButton}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CommentApproveDialog;
