import {
  Box,
  Button,
  Container,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import styles from "./Management.module.css";
import TabsView from "../Components/Comments/TabsView";
import ReviewList from "../Components/Comments/CommentList";
import { useGetAllReviewsQuery } from "../Store/Services/reviewService";
import ReviewDialog from "../Components/Comments/CommentDialog";
import { useDispatch } from "react-redux"
import { closeReviewModal } from "../Store/Slices/reviewSlice";

const CommentsManagement = () => {
  const { data, error, isLoading } = useGetAllReviewsQuery();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeReviewModal());
}
  return (
    <Paper elevation={3} className={styles.pageContainer} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}E_SERVICE/thumbnail_Management.png)`}}>
      <Box>
        <Toolbar className={styles.subNavBar}>
          <Typography className={styles.topic}>Review Management</Typography>
        </Toolbar>
      </Box>
      <Container>
        {isLoading ? (
          <Typography></Typography>
        ) : error ? (
          <Typography></Typography>
        ) : (
          <ReviewList data={data.reviews} /> 
        )}
      </Container>
      <ReviewDialog handleClose={handleClose} />

    </Paper>
  );
};

export default CommentsManagement;
