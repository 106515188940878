import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  Typography,
  IconButton,
  Button,
  Autocomplete,
  TextField,
  Grid,
  Avatar,
  OutlinedInput,
} from "@mui/material";
import {
  useGetResourceByIdQuery,
  useUpdateResourceMutation,
} from "../../Store/Services/ResourceService";

import { useDispatch } from "react-redux";
import { openEditResourceModal } from "../../Store/Slices/resourceSlice";
import styles from "./Resource.module.css";
import { useUploadResImageMutation } from "../../Store/Services/ImageService";
import { Close, Edit } from "@mui/icons-material";

import { useGetAllUserQuery } from "../../Store/Services/UserService";
import { useGetAllTopCategoriesQuery } from "../../Store/Services/CategoryService";
import { useGetAllAreasQuery } from "../../Store/Services/AreaService";

const schema = yup.object({
  name: yup.string().trim().required(" *"),
  telNo: yup.string().trim().required("*"),
  mobileNo: yup.string().trim().required("*"),
  idNo: yup.string().trim().required("*"),
  email: yup
    .string()
    .trim()
    .required("* ")
    .email("Res Email must be a valid email"),
  contactNum: yup.string(),
});

const EditResource = ({ handleClose }) => {
  const dispatch = useDispatch();

  const resource = useSelector((state) => state.resource);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    data: areaDatas,
    isSuccess: areaSuccess,
    isLoading: areaLoading,
  } = useGetAllAreasQuery();

  const {
    data: catDatas,
    isSuccess: catSuccess,
    isLoading: catLoading,
  } = useGetAllTopCategoriesQuery();

  const [updateResource] = useUpdateResourceMutation();

  const {
    data: resourceDetails,
    isSuccess: resourceSuccess,
    isLoading: resourceLoading,
  } = useGetResourceByIdQuery(resource.selectedResource);

  const [resArea, setResArea] = useState([]);
  const [deletedArea, setDeletedArea] = useState([]);
  const [newResArea, setNewResArea] = useState([]);
  const [selectedResArea, setSelectedResArea] = useState([]);

  const [resCat, setResCat] = useState([]);
  const [deletedCat, setDeletedCat] = useState([]);
  const [newResCat, setNewResCat] = useState([]);
  const [selectedResCat, setSelectedResCat] = useState([]);
  const [profileImage, setProfileImage] = useState();

  useEffect(() => {

    if (resourceSuccess && resourceDetails) {

      if (
        Array.isArray(resourceDetails.resAreaDTOS) &&
        resourceDetails.resAreaDTOS.length > 0 &&
        Array.isArray(resourceDetails.resCatDTOS) &&
        resourceDetails.resCatDTOS.length > 0
      ) {

        setSelectedResArea(resourceDetails.resAreaDTOS);
        setSelectedResCat(resourceDetails.resCatDTOS);

        reset({
          name: resourceDetails?.name,
          idNo: resourceDetails?.idNo,
          telNo: resourceDetails?.telNo,
          mobileNo: resourceDetails?.mobileNo,
          email: resourceDetails?.email,
          pic: resourceDetails?.pic,
        });

        setProfileImage(resourceDetails?.pic);

        const initialSelectedNames = {
          firstName: resourceDetails?.name.split(" ")[0] || "",
          lastName: resourceDetails?.name.split(" ")[1] || "",
        };
        setSelectedNames(initialSelectedNames);

      } 
    } 
  }, [resourceDetails, resourceSuccess, reset]);

  // IMAGE UPLOAD
  const [uploadImage] = useUploadResImageMutation();

  const onImageUpload = async (e) => {
    var data = new FormData();
    data.append("images", e.target.files[0]);
    data.append("folder", resourceDetails.resourceId);

    try {
      const result = await uploadImage(data).unwrap();
      // console.log("Upload result:", result);

      if (result.length > 0 && result[0].length > 0) {
        // console.log("Image URL:", result[0][0]);
        setProfileImage(result[0][0]);
      } else {
        // console.error("No valid image URLs found in the result:", result);
      }
    } catch (err) {
      console.error("Error uploading image:", err);
    }
  };

  // NAME USER
  const { data: usersData } = useGetAllUserQuery();
  const users = usersData || [];
  const [selectedNames, setSelectedNames] = React.useState(null);

  //SUBMIT

  const onSubmit = async (data) => {
    // console.log("profileImage in onSubmit:", profileImage);
    // console.log("resourceDetails[0].pic in onSubmit:", resourceDetails[0]?.pic);

    const deletedAreasIds = deletedArea.map((area) => area.resAreaId);
    const deletedCatsIds = deletedCat.map((cat) => cat.resCatId);

    const dataSet = {
      name: selectedNames
        ? [`${selectedNames.firstName} ${selectedNames.lastName}`]
        : [],
      idNo: data.idNo,
      telNo: data.telNo,
      mobileNo: data.mobileNo,
      email: data.email,
      resAreaDTOS: newResArea.map((area) => ({
        areaId: area.areaId,
        resAreaId: area.resAreaId,
        desc: area.desc,
      })),
      resCatDTOS: newResCat.map((cat) => ({
        catId: cat.catId,
        resCatId: cat.resCatId,
        catName: cat.catName,
      })),
      deleted: {
        areas: deletedAreasIds.length > 0 ? deletedAreasIds : [],
        categories: deletedCatsIds.length > 0 ? deletedCatsIds : [],
      },
      resourceId: resource.selectedResource,
      pic: profileImage
    };

    if(selectedResArea.length!==0 && selectedResCat.length!==0){
      try {
        const respond = await updateResource(dataSet).unwrap();

        dispatch(openEditResourceModal(false));
        toast.success("Resource successfully updated!", {
          position: "bottom-right",
        });

      } catch (err) {
        console.error("Failed to save Resource: ", err);
        toast.error("Error, something went wrong!");
      }
      handleClose();
    }
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <Typography fontWeight={600} variant={"h6"}>
          Edit Resource
        </Typography>
        <IconButton className={styles.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <label htmlFor="contained-button-file">
        <div
          className={styles.avatarContainer}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 5px 5px 0",
          }}
        >
          <form encType="multipart/form-data">
            <Avatar
              className={styles.avatar}
              style={{
                width: "110px",
                height: "110px",
                border: "solid 1px #5A9BD8",
                marginTop: "30px",
              }}
              src={
                profileImage
                  ? `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
                  : resourceDetails && resourceDetails.length > 0
                  ? `${process.env.REACT_APP_IMAGE_URL}${resourceDetails.pic}`
                  : `${process.env.REACT_APP_IMAGE_URL}Default/User_Avatar.jpg`
              }
              onLoad={() =>
                console.log(
                  "Avatar loaded:",
                  `${process.env.REACT_APP_IMAGE_URL}${profileImage}`
                )
              }
              onError={() => console.error("Error loading Avatar")}
            />

            <div style={{ margin: "-2rem 0 0 5rem" }}>
              <IconButton
                outlined="contained"
                style={{
                  width: "2rem",
                  borderRadius: "3rem",
                  backgroundColor: "#5A9BD8",
                }}
                component="span"
              >
                <input
                  onChange={onImageUpload}
                  sx={{ "&": { marginTop: "0px" }, justifyContent: "right" }}
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                />
                <Edit sx={{ width: "1rem", height: "1rem", color: "white" }} />
              </IconButton>
            </div>
          </form>
        </div>
      </label>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Name:</label>
            {errors.userId && errors.userId.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <Autocomplete
              size="small"
              id="name-select"
              options={users}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={(_, newValue) => setSelectedNames(newValue || null)}
              value={selectedNames}
              renderInput={(params) => (
                <TextField
                  className={styles.formInput}
                  {...params}
                  variant="outlined"
                />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: "50%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>NIC No:</label>
            {errors.idNo && errors.idNo.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <OutlinedInput
              {...register("idNo")}
              className={styles.formInput}
              placeholder="Enter NIC"
              size="small"
              fullWidth
            />
          </div>
        </Grid>

        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Email Address:</label>
            {errors.email && errors.email.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <OutlinedInput
              {...register("email")}
              className={styles.formInput}
              placeholder="Enter Email Address"
              size="small"
              fullWidth
            />
          </div>
        </Grid>

        <Grid item container xs={12} columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} md={6} sx={{ width: "50%" }}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}>Telephone Number:</label>
              {errors.telNo && errors.telNo.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("telNo")}
                className={styles.formInput}
                placeholder="Enter Telephone Number"
                type="number"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} sx={{ width: "50%" }}>
            <div className={styles.formItem}>
              <label className={styles.formLabel}>Mobile Number:</label>
              {errors.mobileNo && errors.mobileNo.type === "required" && (
                <span className={styles.validation}> *</span>
              )}
              <OutlinedInput
                {...register("mobileNo")}
                className={styles.formInput}
                placeholder="Enter Mobile Number"
                type="number"
                size="small"
                fullWidth
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Resource Area:</label>

            {selectedResArea.length==0 && (
              <span className={styles.validation}> *</span>
            )}

            <Controller
              name="resArea"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  options={areaDatas || []}
                  multiple
                  filterSelectedOptions
                  getOptionLabel={(option) => option.desc}
                  isOptionEqualToValue={(option, value) =>
                    option.areaId === value.areaId
                  }
                  value={selectedResArea}
                  onChange={(_, newArea, reason, detail) => {
                    if (reason === "removeOption") {
                      setSelectedResArea((prev) =>
                        prev.filter(
                          (area) => area.areaId !== detail.option.areaId
                        )
                      );
                      setDeletedArea((prev) => [...prev, detail.option]);
                    } else if (reason === "selectOption") {
                      const isNewArea = !selectedResArea.some(
                        (area) => area.areaId === detail.option.areaId
                      );

                      if (isNewArea) {
                        setNewResArea((prev) => [...prev, detail.option]);
                      }

                      setSelectedResArea((prev) => [...prev, detail.option]);
                    }
                    setResArea(
                      newArea.map((area) => ({
                        areaId: area.areaId,
                        desc: area.desc,
                        areaDeleteId: area.areaDeleteId,
                      }))
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              )}
            />
          </div>
        </Grid>

        <Grid item xs={12} sx={{ width: "100%" }}>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Resource Category:</label>

            {selectedResCat.length==0 && (
              <span className={styles.validation}> *</span>
            )}

            <Controller
              name="resCat"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  options={catDatas || []}
                  multiple
                  filterSelectedOptions
                  getOptionLabel={(option) => option.catName}
                  isOptionEqualToValue={(option, value) =>
                    option.catId === value.catId
                  }
                  value={selectedResCat}
                  onChange={(_, newCat, reason, detail) => {
                    if (reason === "removeOption") {
                      setSelectedResCat((prev) =>
                        prev.filter((cat) => cat.catId !== detail.option.catId)
                      );
                      setDeletedCat((prev) => [...prev, detail.option]);
                    } else if (reason === "selectOption") {
                      setNewResCat((prev) => [...prev, detail.option]);
                      setSelectedResCat((prev) => [...prev, detail.option]);
                    }
                    setResCat(
                      newCat.map((cat) => ({
                        catd: cat.catId,
                        catName: cat.catName,
                        catDeleteId: cat.catDeleteId,
                      }))
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              )}
            />
          </div>
        </Grid>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className={styles.submitButton}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditResource;
