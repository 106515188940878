import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import SubmitedList from "./SubmitedList";
import ApprovedList from "./ApprovedList";
import RejectedList from "./RejectedList";
import { useGetAllReviewsQuery } from "../../Store/Services/reviewService";

const TabsView = () => {
  const [value, setValue] = useState(0);
  const { data, error, isLoading, isSuccess } = useGetAllReviewsQuery();

  // Status mapping: Submitted -> 1, Approved -> 2, Rejected -> 3
  const getFilteredData = (status) =>
    data?.reviews.filter((review) => review.status === status) || [];

  // useEffect(() => {
  //   if (isSuccess) {
  //     console.log("Fetched Reviews:", data);
  //   }
  //   if (error) {
  //     console.error("Error fetching reviews:", error);
  //   }
  // }, [isSuccess, error, data]);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <Box sx={{ width: "100%", typography: "body1", paddingTop: "2rem" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ "& .MuiTabs-flexContainer": { justifyContent: "space-between" } }}
      >
        <Tab label="SUBMITTED" sx={{ width: "33.33%" }} />
        <Tab label="APPROVED" sx={{ width: "33.33%" }} />
        <Tab label="REJECTED" sx={{ width: "33.33%" }} />
      </Tabs>

      <Box my={2}>
        {value === 0 && <SubmitedList data={getFilteredData(1)} />}
        {value === 1 && <ApprovedList data={getFilteredData(2)} />}
        {value === 2 && <RejectedList data={getFilteredData(3)} />}
      </Box>
    </Box>
  );
};

export default TabsView;
