import { useSelector, useDispatch } from "react-redux";
import { useDeleteReviewMutation } from "../../Store/Services/reviewService";
import { closeReviewModal } from "../../Store/Slices/reviewSlice";
import DeletePopUp from "../Shared/DeletePopUp";


const DeleteReview = ({handleClose}) => {
    const dispatch = useDispatch();
    const {selectedReview} = useSelector((state) => state.review);

    const [deleteReview] = useDeleteReviewMutation();

    const onDeleteConfirm = async () => {
        try{
            await deleteReview(selectedReview).unwrap();
        }catch(err){
            console.error('Failed to delete the Review',err);
        }
        dispatch(closeReviewModal());
    };

    return(
        <DeletePopUp
               data="Review"
               close={() => dispatch(closeReviewModal())}
               onDeleteConfirm={onDeleteConfirm}
        />
    )
};

export default DeleteReview;