import React from "react";
import { useDispatch } from "react-redux";
import {
  openDeleteReviewModal,
  openEditReviewModal,
} from "../../Store/Slices/reviewSlice";
import { PaginationDetails } from "../../Utils/Utils";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Pagination,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ReviewList = ({ data }) => {
  const dispatch = useDispatch();
  const { page, count, handleChange, _DATA } = PaginationDetails(data);

  const handleEdit = (reviewId) => {
    dispatch(openEditReviewModal(reviewId));
  };

  const handleDelete = (reviewId) => {
    dispatch(openDeleteReviewModal(reviewId));
  };

  const currentData = _DATA?.currentData() || data;

  const roles = sessionStorage.getItem("roles");

  return (
    <>
      <TableContainer sx={{ height: "43rem" }}>
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0px 4px",
            height: "max-content",
            border: 'none',
          }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: 'none' }} width={50}>Review Id </TableCell>
              <TableCell width={50}> Request Id </TableCell>
              <TableCell width={"30%"}>
                Rating
              </TableCell>
              <TableCell  width={"30%"}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((review) => (
              <TableRow key={review.reviewId}>
                <TableCell sx={{ border: 'none' }} >{review.reviewId}</TableCell>
                <TableCell sx={{ border: 'none' }} >{review.reqId}</TableCell>
                <TableCell sx={{ border: 'none' }} >{review.rating}</TableCell>
                <TableCell sx={{ border: 'none' }} ><IconButton onClick={() => handleEdit(review.reviewId)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(review.reviewId)}>
                    <DeleteIcon />
                  </IconButton></TableCell>
              </TableRow>
            ))}
          </TableBody>{" "}
        </Table>
      </TableContainer>
      <Box my={2} display="flex" justifyContent="center">
        <Pagination
          count={count}
          page={page}
          onChange={handleChange}
          variant="outlined"
          color="primary"
          size="large"
        />
      </Box>
    </>
  );
};

export default ReviewList;
