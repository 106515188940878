// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddArea_headingContainer__gZ43I {
  display: flex;
  justify-content: space-between;
}

.AddArea_closeButton__2p4y9 {
  padding: 0.2rem;
  border: 1px #5A9BD8 solid;
  color: #5A9BD8;
}

.AddArea_formItem__IJB0I {
  margin-top: 1rem;
}

.AddArea_formLabel__OT47x {
  font-weight: 600;
}

.AddArea_formInput__8PsA3 {
  margin-top: 0.3rem;
}

.AddArea_validation__g423P {
  color: red;
}

.AddArea_buttonContainer__N\\+0nN {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.AddArea_submitButton__\\+UZ9o {
  width: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Area/AddArea.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".headingContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.closeButton {\r\n  padding: 0.2rem;\r\n  border: 1px #5A9BD8 solid;\r\n  color: #5A9BD8;\r\n}\r\n\r\n.formItem {\r\n  margin-top: 1rem;\r\n}\r\n\r\n.formLabel {\r\n  font-weight: 600;\r\n}\r\n\r\n.formInput {\r\n  margin-top: 0.3rem;\r\n}\r\n\r\n.validation {\r\n  color: red;\r\n}\r\n\r\n.buttonContainer {\r\n  margin-top: 2rem;\r\n  margin-bottom: 1rem;\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.submitButton {\r\n  width: 10rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headingContainer": `AddArea_headingContainer__gZ43I`,
	"closeButton": `AddArea_closeButton__2p4y9`,
	"formItem": `AddArea_formItem__IJB0I`,
	"formLabel": `AddArea_formLabel__OT47x`,
	"formInput": `AddArea_formInput__8PsA3`,
	"validation": `AddArea_validation__g423P`,
	"buttonContainer": `AddArea_buttonContainer__N+0nN`,
	"submitButton": `AddArea_submitButton__+UZ9o`
};
export default ___CSS_LOADER_EXPORT___;
