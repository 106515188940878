import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Paper,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleLoginDialog,
  toggleRegisterDialog,
  selectAuth,
} from "../../Store/Slices/authSlice";
import SignUpForm from "./SignUpForm";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./SignUpForm.module.css";

const SignUpDialog = () => {
  const dispatch = useDispatch();
  const { isRegisterDialogOpen } = useSelector(selectAuth);

  const handleClose = () => {
    dispatch(toggleRegisterDialog());
  };

  const handleSwitchToLogin = () => {
    dispatch(toggleRegisterDialog());
    dispatch(toggleLoginDialog());
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isRegisterDialogOpen}
      onClose={handleClose}
    >
      <Paper elevation={3} className={styles.paperStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              onClick={handleClose}
              style={{ position: "absolute", right: "8px", top: "8px" }}
              aria-label="Close"
            >
              <CloseIcon />
            </Button>
            <div className={styles.formContainer}>
              <img
                className={styles.logo}
                alt="logo"
                src={`${process.env.REACT_APP_IMAGE_URL}DoneRight/HOME/doneRightLogo.png`}
                loading="lazy"
              />
              <Typography variant="h6" component="h3" className={styles.signUp}>
                Sign Up
              </Typography>
              <SignUpForm />
              <Divider sx={{ marginTop: "20px" }}>OR</Divider>

              <Button color="secondary" fullWidth className={styles.formItem} onClick={handleSwitchToLogin}>
                Already have an account?
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
};

export default SignUpDialog;
