import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  OutlinedInput,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { string } from "yup";
import styles from "./GuestDetails.module.css";
import { Close } from "@mui/icons-material";
import LocationDialog from "../Address/LocationDialog";
import { useGetAreaByStateIdQuery } from "../../Store/Services/AreaService";
import { useGetAllStatesQuery } from "../../Store/Services/StateService";
import { toast } from "react-toastify";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useDispatch } from "react-redux";
import { saveAddressDetails } from "../../Store/Slices/addressSlice";
import { useCreateGuestMutation } from "../../Store/Services/UserService";
import { SET_ADDRESS_DETAILS } from "../../Pages/CheckoutPage";
import ExistingUserDialog from "../Auth/ExistingUserDialog";
import LoginDialog from "../Login/LoginDialog";


const schema = yup
  .object({
    email: yup
      .string()
      .required("")
      .matches(
        "^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+.[a-z]+$",
        "Email format is invalid!"
      ),
    mobile: string()
      .required("")
      .matches(/^\d{10}$/, "Please enter a valid 10-digit phone number"),
    stateId: yup.string().notOneOf(["init"]).required(""),
    areaId: yup.string().notOneOf(["init"]).required(""),
    addLine1: yup.string().required(""),
    // addLine2: yup.string().required(""),
    // addLine3: yup.string().required(""),
  })
  .required();

const GuestDetails = ({ open, handleClose, onCloseFinal }) => {
  const dispatch = useDispatch();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [createGuest] = useCreateGuestMutation();

  const [location, setLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(false);
  const [state, setState] = useState(null);
  const [area, setArea] = useState(null);
  const [openExistingUserDialog, setOpenExistingUserDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openProceedDialog, setOpenProceedDialog] = useState(false);

  const { data: stateData } = useGetAllStatesQuery();
  const states = stateData?.map((state) => (
    <MenuItem
      key={state.stateId}
      value={state.stateId}
      onClick={() => {
        setState(state.stateId);
      }}
    >
      {state.state}
    </MenuItem>
  ));

  const { data: areaData, isSuccess: isAreaSuccess } = useGetAreaByStateIdQuery(
    state ? state : skipToken,
    { enabled: !!state }
  );

  useEffect(() => {
    if (areaData) {
      if (areaData.length === 1) {
        setArea(areaData[0].areaId);
      } else {
        setArea("init");
      }
    } else {
      setArea("init");
    }
  }, [areaData]);
  const areas =
    isAreaSuccess &&
    areaData?.map((area) => (
      <MenuItem
        key={area.areaId}
        value={area.areaId}
        onClick={() =>
          console.log("Selected Area:", {
            areaId: area.areaId,
            areaName: area.desc,
          })
        }
      >
        {area.desc}
      </MenuItem>
    ));

  const onClose = () => {
    setOpenProceedDialog(true);
    handleClose();
    reset();
    setLocation(null);
    setState(null);
  };

  const handleLoginRedirect = () => {
    setOpenExistingUserDialog(false);
    setOpenLoginDialog(true);
  };

  useEffect(() => {
    const isSignedIn = sessionStorage.getItem("isSignedIn");
    if (isSignedIn === "1") {
      handleClose();
    }
  }, [handleClose]);

  const onFormSubmit = async (data) => {
    const dataSet = {
      ...data,
      areaName: areaData?.find((a) => a.areaId === area)?.desc,
      stateName: stateData?.find((s) => s.stateId === state)?.state,
    };

    try {
      const response = await createGuest({
        email: dataSet.email,
        username: dataSet.email,
        mobile: dataSet.mobile,
      }).unwrap();
      // console.log("API:", response);
      dispatch(saveAddressDetails(dataSet));
      // dispatch({ type: SET_ADDRESS_DETAILS, payload: dataSet });
      sessionStorage.setItem("isSignedIn", 2);
      sessionStorage.setItem("access_token", response.token);
      // console.log("to Redux :", dataSet);
      // toast.success("Details saved successfully!");
      onClose();
      onCloseFinal();
    } catch (err) {
      console.error("Failed to save details: ", err);
      if (err?.status === 406) {
        setOpenExistingUserDialog(true);
      } else {
        toast.error("Error, something went wrong!");
      }
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent className={styles.dialog}>
        <div className={styles.headingContainer}>
          <Typography fontWeight={600} variant={"h6"}></Typography>
          <IconButton className={styles.closeButton} onClick={() => onClose()}>
            <Close />
          </IconButton>
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(onFormSubmit)(e);
          }}
        >
          {" "}
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Email</label>
            {errors.email && errors.email.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <OutlinedInput
              className={styles.formInput}
              {...register("email")}
              size="small"
              fullWidth
            />
            {errors.email && (
              <FormHelperText error> {errors.email?.message} </FormHelperText>
            )}
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Mobile</label>
            {errors.mobile && errors.mobile.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <OutlinedInput
              className={styles.formInput}
              {...register("mobile")}
              size="small"
              fullWidth
            />
            {errors.mobile && (
              <FormHelperText error> {errors.mobile?.message} </FormHelperText>
            )}
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>State</label>
            {errors.stateId && errors.stateId.type === "notOneOf" && (
              <span className={styles.validation}> *</span>
            )}
            <Select
              {...register("stateId")}
              value={state || "init"}
              className={styles.formInput}
              fullWidth
              size="small"
            >
              <MenuItem value="init">Select State</MenuItem>
              {states}
            </Select>
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Area </label>
            {errors.areaId && errors.areaId.type === "notOneOf" && (
              <span className={styles.validation}> *</span>
            )}
            <Select
              {...register("areaId")}
              value={area || "init"}
              onChange={(e) => setArea(e.target.value)}
              className={styles.formInput}
              fullWidth
              size="small"
            >
              <MenuItem value="init">Select Area</MenuItem>
              {areas}
            </Select>
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Address Line 1 </label>
            {errors.addLine1 && errors.addLine1.type === "required" && (
              <span className={styles.validation}> *</span>
            )}
            <OutlinedInput
              className={styles.formInput}
              {...register("addLine1")}
              size="small"
              fullWidth
            />
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Address Line 2 </label>
            <OutlinedInput
              className={styles.formInput}
              {...register("addLine2")}
              size="small"
              fullWidth
            />
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Address Line 3 </label>
            <OutlinedInput
              className={styles.formInput}
              {...register("addLine3")}
              size="small"
              fullWidth
            />
          </div>
          {/* Submit Button */}
          <div className={styles.buttonContainer}>

            <Button
              className={styles.locationButton}
              variant="contained"
              onClick={() => setOpenLocation(true)}
            >
              Add Location
            </Button>
            <Button
              type="submit"
              className={styles.submitButton}
              variant="contained"
            >
              Next
            </Button>
          </div>
        </form>
        <LocationDialog
          open={openLocation}
          onClose={() => setOpenLocation(false)}
          location={location}
          setLocation={setLocation}
        />
      </DialogContent>
      <ExistingUserDialog
        open={openExistingUserDialog}
        onClose={() => setOpenExistingUserDialog(false)}
        onLogin={() => {
          setOpenExistingUserDialog(false);
          handleClose(); 
        }}      />

      <LoginDialog
        open={openLoginDialog}
        onClose={() => setOpenLoginDialog(false)}
        onCloseFinal={() => {}}
      />
    </Dialog>
  );
};

export default GuestDetails;
