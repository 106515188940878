import React from "react";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux"; 
import { toggleLoginDialog } from "../../Store/Slices/authSlice";

const ProceedDialog = ({ open, onProceedWithoutLogin }) => {
  const dispatch = useDispatch();

  const handleSignInClick = () => {
    dispatch(toggleLoginDialog()); 
    // if (onClose) onClose(); 
  };

  return (
    <Dialog
      open={open}
      sx={{
        marginLeft: "0rem",
        marginRight: "0rem",
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <Container maxWidth="md">
          <Typography
            my={3}
            variant="h5"
            component="div"
            color="text.primary"
            textAlign="center"
            fontWeight="bold"
            marginBottom="2rem"
          >
            You are not signed in
          </Typography>
          <Typography
            component="div"
            color="text.primary"
            textAlign="center"
            marginBottom="2rem"
          >
            If you have an account with us, please sign in. Alternatively, you
            are welcome to continue as a guest.
          </Typography>
          <Button
            onClick={handleSignInClick} 
            style={{ borderRadius: "50px", marginBottom: "0.5rem" }}
            fullWidth
            variant="contained"
            aria-label="Sign In"
          >
            Sign In
          </Button>
          <Button
            onClick={onProceedWithoutLogin}
            style={{ borderRadius: "50px", marginBottom: "0.5rem" }}
            fullWidth
            variant="contained"
            aria-label="Proceed Without Signing In"
          >
            Continue as Guest
          </Button>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default ProceedDialog;
