import { createSlice } from "@reduxjs/toolkit";

export const reviewSlice = createSlice({
  name: "review",
  initialState: {
    modalStatus: false,
    selectedReview: null,
    reviewPageOpen: false,
  },
  reducers: {
    openAddReviewModal: (state) => {
      state.modalStatus = "add"; 
    },

    openEditReviewModal: (state, action) => {
      state.modalStatus = "edit";
      state.selectedReview = action.payload;
      state.reviewPageOpen = action.payload;
    },
    openDeleteReviewModal: (state, action) => {
      state.modalStatus = "delete";
      state.selectedReview = action.payload;
    },
    closeReviewModal: (state) => {
      state.modalStatus = false;
      state.selectedReview = null;
    }
  }
});

export const {
  openAddReviewModal,
  openEditReviewModal,
  openDeleteReviewModal,
  closeReviewModal
} = reviewSlice.actions;

export default reviewSlice.reducer;



