import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  OutlinedInput,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./AddAddress.module.css";
import { Close } from "@mui/icons-material";
import LocationDialog from "./LocationDialog";
import { useGetAreaByStateIdQuery } from "../../Store/Services/AreaService";
import { useGetAllStatesQuery } from "../../Store/Services/StateService";
import { toast } from "react-toastify";
import { useCreateAddressMutation } from "../../Store/Services/AddressService";
import { useGetAddressByIdQuery } from "../../Store/Services/AddressService";
import { useUpdateAddressMutation } from "../../Store/Services/AddressService";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useDispatch } from "react-redux";
import { saveAddressDetails } from "../../Store/Slices/addressSlice";

const schema = yup
  .object({
    contactName: yup.string().required(""),
    stateId: yup.string().notOneOf(["init"]).required(""),
    areaId: yup.string().notOneOf(["init"]).required(""),
    addLine1: yup.string().required(""),
    addLine2: yup.string().required(""),
    addLine3: yup.string().required(""),
  })
  .required();

const AddAddress = ({ open, handleClose, editAddressId, from }) => {
  const { data: initialAddressData, isSuccess: isInitialAddressSuccess } =
  useGetAddressByIdQuery(editAddressId ? editAddressId : skipToken);

  const dispatch = useDispatch();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [updateAddress] = useUpdateAddressMutation();
  const [createAddress] = useCreateAddressMutation();

  useEffect(() => {
    if (isInitialAddressSuccess && initialAddressData) {
      reset({
        contactName: initialAddressData[0].contactName,
        stateId: initialAddressData[0].stateId,
        areaId: initialAddressData[0].areaId,
        addLine1: initialAddressData[0].addLine1,
        addLine2: initialAddressData[0].addLine2,
        addLine3: initialAddressData[0].addLine3,
      });
      setState(initialAddressData[0].stateId);
      setArea(initialAddressData[0].areaId);
    } else {
      reset();
    }
  }, [isInitialAddressSuccess, initialAddressData, reset, editAddressId]);

  const [location, setLocation] = useState(null);
  const [openLocation, setOpenLocation] = useState(false);
  const [state, setState] = useState(null);
  const [area, setArea] = useState(null);

  const { data: stateData } = useGetAllStatesQuery();
  const states = stateData?.map((state) => (
    <MenuItem
      key={state.stateId}
      value={state.stateId}
      onClick={() => setState(state.stateId)}
    >
      {state.state}
    </MenuItem>
  ));

  const { data: areaData, isSuccess: isAreaSuccess } = useGetAreaByStateIdQuery(
    state ? state : skipToken,
    { enabled: !!state }
  );

  useEffect(() => {
    if (areaData && areaData.length > 0) {
      setArea(areaData[0].areaId);
    } else {
      setArea("init");
    }
  }, [areaData]);

  const areas =
    isAreaSuccess &&
    areaData?.map((area) => (
      <MenuItem key={area.areaId} value={area.areaId}>
        {area.desc}
      </MenuItem>
    ));

  const onClose = () => {
    handleClose();
    reset();
    setLocation(null);
    setState(null);
  };

  const onFormSubmit = async (data) => {
    const dataSet = {
      ...data,
    };

    try {
      if (editAddressId) {
        const respond = await updateAddress({
          userContactId: editAddressId,
          ...dataSet,
        }).unwrap();
        toast.success("Address successfully updated!");
      } else {
        const respond = await createAddress(dataSet).unwrap();
        toast.success("Address successfully added!");
      }
      onClose();
    } catch (err) {
      if (err?.status === 401) {
        // Handle unauthorized
        dispatch(saveAddressDetails(dataSet));
        onClose();
      } else {
        console.error("Failed to save the Address: ", err);
        toast.error("Error, something went wrong!");
      }
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogContent className={styles.dialog}>
        <div className={styles.headingContainer}>
          <Typography fontWeight={600} variant={"h6"}>
            {from === "edit" ? "Edit Address" : "Add Address"}
          </Typography>
          <IconButton className={styles.closeButton} onClick={() => onClose()}>
            <Close />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {/* <div className={styles.formItem}>
            <label className={styles.formLabel}>Location</label>
            <OutlinedInput
              className={styles.formInput}
              onClick={() => setOpenLocation(true)}
              value={location ? `latitude: ${location.lat}, longitude: ${location.lng}` : null}
              size="small"
              fullWidth
              placeholder="Select Location"
            />
          </div> */}
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Contact Name</label>
            <OutlinedInput
              className={styles.formInput}
              {...register("contactName")}
              size="small"
              fullWidth
            />
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}>State</label>
            {errors.state && errors.state.type === "notOneOf" && (
              <span className={styles.validation}> *</span>
            )}
            <Select
              {...register("stateId")}
              value={state || "init"}
              className={styles.formInput}
              fullWidth
              size="small"
            >
              <MenuItem value="init">Select State</MenuItem>
              {states}
            </Select>
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Area </label>
            {errors.area && errors.area.type === "notOneOf" && (
              <span className={styles.validation}> *</span>
            )}
            <Select
              {...register("areaId")}
              value={area || "init"}
              onChange={(e) => setArea(e.target.value)}
              className={styles.formInput}
              fullWidth
              size="small"
            >
              <MenuItem value="init">Select Area</MenuItem>
              {areas}
            </Select>
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Address Line 1 </label>
            <OutlinedInput
              className={styles.formInput}
              {...register("addLine1")}
              size="small"
              fullWidth
            />
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Address Line 2 </label>
            <OutlinedInput
              className={styles.formInput}
              {...register("addLine2")}
              size="small"
              fullWidth
            />
          </div>
          <div className={styles.formItem}>
            <label className={styles.formLabel}> Address Line 3 </label>
            <OutlinedInput
              className={styles.formInput}
              {...register("addLine3")}
              size="small"
              fullWidth
            />
          </div>
          {/* Submit Button */}
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              className={styles.submitButton}
              variant="contained"
            >
              Save
            </Button>
            <Button
              className={styles.locationButton}
              variant="contained"
              onClick={() => setOpenLocation(true)}
            >
              Add Location
            </Button>
          </div>
        </form>
        <LocationDialog
          open={openLocation}
          onClose={() => setOpenLocation(false)}
          location={location}
          setLocation={setLocation}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddAddress;
